import waves from '@/directive/waves' // 水波纹指令
import moment from 'moment'
import FileSaver from 'file-saver'
import JsBarcode from "jsbarcode"
import QRCode from "qrcodejs2"
import { getSystemSettings } from '@/api/common'
import { getAdminToken } from '@/utils/storages'
export default {
    directives: {
        waves
    },
    data() {
        return {
            uploadHeaders: { 'Authorization': 'bearer ' + getAdminToken() },
            uploadBaseUrl: (() => {
                let baseApi = ""
                if (process.env.NODE_ENV === 'development') {
                    baseApi = window.configs.baseTestApi
                } else if (process.env.NODE_ENV === 'production') {
                    if (process.env.VUE_APP_FLAG === 'pro') {
                        baseApi = window.configs.baseApi
                    } else if (process.env.VUE_APP_FLAG === 'uat') {
                        baseApi = window.configs.baseUatApi
                    } else if (process.env.VUE_APP_FLAG === 'test') {
                        baseApi = window.configs.baseTestApi
                    } else {
                        baseApi = window.configs.baseApi
                    }
                }
                return baseApi
            })(),
            appPageSizes: window.$common.appPageSizes,
            appSiteName: (this.$store && this.$store.getters && this.$store.getters.systemConfig 
                            && this.$store.getters.systemConfig.systemName) 
                            || (window.configs && window.configs.siteName) 
                            || process.env.VUE_APP_SiteName, // 有存储
            broadcastNames: { refreshTab: "refreshTab" } // 需要广播的名称
        }
    },
    activated() {
        this.executeScrollbarUpdate()
    },
    methods: {
        /* 获取document.body.getBoundingClientRect()对象 */
        getBoundingClient() {
            return document.body.getBoundingClientRect()
        },
        /* 执行滚动条更新 */
        executeScrollbarUpdate(obj) {
            this.$nextTick(() => {
                if (obj) {
                    obj.$emit("scrollbarUpdate")
                } else {
                    this.$emit("scrollbarUpdate")
                }
            })
        },
        // 发送广播信息
        setBroadcast(bdName, data) {
            this.$root.eventHub.$emit(bdName, data)
        },
        // 接收广播信息
        getBroadcast(bdName, callback) {
            try {
                this.$root.eventHub.$on(bdName, res => {
                    // 名称相同才执行
                    if (res.name.indexOf(this.$options.name) != -1) {
                        if (callback) {
                            try {
                                callback(res)
                            } catch(ex) {
                                console.error("接收广播后执行方法报错：" + ex)
                            }
                        }
                    }
                })
            } catch(e) {
                console.error("接广播报错：" + e)
            }
        },
        // 设置系统配置storege
        setSystemConfig() {
            getSystemSettings().then(res => {
                this.$store.dispatch("setSystemConfig", res)
                this.appSiteName = res && res.systemName || this.appSiteName
                // 同时设置title
                document.title = this.appSiteName
            })
        },
        // 设置页头--路由表中配置
        setSysPageHeader(item) {
            if (this.$route && this.$route.meta && this.$route.meta.isPageHead) {
                let data = {
                    title: item && item.title || this.$route.meta.title,
                    breadcrumb: item && item.breadcrumb || [this.$route.meta.parent, this.$route.meta.title]
                }
                if (item && item.desc) {
                    data.desc = item.desc
                }
                this.$store.dispatch("setSysPageHeader", data)
            } else {
                this.$store.dispatch("setSysPageHeader", null)
            }
        },
        /* MessageBox重写 */
        configBox(content, title, options) {
            let device = this.$store.getters.device
            options = options || {}
            options.title = title || "提示"
            options.message = content || ""
            options.type = options.type || "warning"
            options.customClass = options.customClass || (device === "mobile" ? "c-el-message-box" : "")
            return options
        },
        alert(content, title, options) {
            options = this.configBox(content, title, options)
            return this.$alert(content, options)
        },
        confirm(content, title, options) {
            options = this.configBox(content, title, options)
            return this.$confirm(content, options)
        },
        prompt(content, title, options) {
            options = this.configBox(content, title, options)
            options.type = ""
            return this.$prompt(content, options)
        },
        msgbox(content, title, options) {
            options = this.configBox(content, title, options)
            return this.$msgbox(options)
        },
        /* Message重写 */
        configMessage(content, options) {
            let device = this.$store.getters.device
            options = options || {}
            options.message = content || ""
            options.type = options.type || "info"
            options.duration = options.duration || 2500
            options.offset = options.offset || 20
            options.dangerouslyUseHTMLString = options.dangerouslyUseHTMLString || false
            options.showClose = options.showClose || false
            options.center = options.center || false
            options.customClass = options.customClass || (device === "mobile" ? "c-el-message-box" : "")
            if (options.onClose && typeof(options.onClose) === "function") {
                options.onClose
            }
            return options
        },
        infoMsg(content, options) {
            options = this.configMessage(content, options)
            options.type = "info"
            return this.$message(options)
        },
        successMsg(content, options) {
            options = this.configMessage(content, options)
            options.type = "success"
            return this.$message(options)
        },
        warningMsg(content, options) {
            options = this.configMessage(content, options)
            options.type = "warning"
            return this.$message(options)
        },
        errorMsg(content, options) {
            options = this.configMessage(content, options)
            options.type = "error"
            return this.$message(options)
        },
        /* 表格自动格式化日期 */
        tableDateFormat(row, column) {
            var date = row[column.property]
            return this.getFormatDate(date)
        },
        // unix时间戳自动格式化为日期
        tableUnixDateFormat(row, column) {
            var date = row[column.property]
            return this.getFormatDate(date, true)
        },
        // 日期时间转换为时间戳
        toUnixStamp(date) {
            return !date ? '' : moment(date).format("X")
        },
        // 根据传的日期时间，转换为指定格式
        getFormatDate(date, isUnix, formatTime) {
            if (isUnix) {
                return !date ? '' : moment.unix(date).format(formatTime || 'YYYY-MM-DD HH:mm:ss')
            } else {
                return !date ? '' : moment(date).format(formatTime || 'YYYY-MM-DD HH:mm:ss')
            }
        },
        // 获取当前日期时间并转换为指定格式
        getFormatDateNow(formatTime) {
            return moment().format(formatTime || 'YYYY-MM-DD HH:mm:ss')
        },
        // 星期几得转换
        getFormatWeek(date, isUnix, isEn, tips) {
            let weekName = ""
            if (!isEn) {
                let week = this.getFormatDate(date, isUnix, "d")
                switch(Number(week)) {
                    case 1: week = "一"; break;
                    case 2: week = "二"; break;
                    case 3: week = "三"; break;
                    case 4: week = "四"; break;
                    case 5: week = "五"; break;
                    case 6: week = "六"; break;
                    case 0: week = "日"; break;
                }
                weekName = tips ? `${tips}${week}` : `星期${week}`
            } else {
                weekName = this.getFormatDate(date, isUnix, "dddd")
            }
            return weekName
        },
        // 本地导出excel
        exportCommonExcel1(title, tableDatas, tHeader, filterVal) {
            require.ensure([], () => {
                const { export_json_to_excel } = require('@/vendor/Export2Excel')
                const data = this.formatJsonAboutExcel(filterVal, tableDatas)
                export_json_to_excel(tHeader, data, title || "导出Excel数据")
                // 生成下载后隐藏loading
                window.$common.closeFullLoading()
            })
        },
        exportCommonExcel(title, tableDatas, tHeader, filterVal, merges, multiHeader2, multiHeader) {
            require.ensure([], () => {
                const { export_json_to_excel } = require('@/vendor/Export2Excel')
                const data = this.formatJsonAboutExcel(filterVal, tableDatas)
                // export_json_to_excel(tHeader, data, title || "导出Excel数据")
                export_json_to_excel({
                    multiHeader2: multiHeader2 || [],
                    multiHeader: multiHeader || [],
                    merges: merges || [],
                    header: tHeader, 
                    data, 
                    filename: title || "导出Excel数据"
                })
                // 生成下载后隐藏loading
                window.$common.closeFullLoading()
            })
        },
        formatJsonAboutExcel(filterVal, jsonData) {
            return jsonData.map(v => filterVal.map(j => v[j]))
            /* return jsonData.map(v => {
                return filterVal.map(j => {
                return v[j]
                })
            }) */
        },
        // 本地导出文件
        exportCommonFile(data, fileName, suffix, type) {
            try {
                // 将json转换成字符串
                /*if (typeof data == 'object') {
                    data = JSON.stringify(data)
                }*/
                data = JSON.stringify(data)
                const blob = new Blob([data], { type: type || "" })
                FileSaver.saveAs(blob, fileName + suffix)
            } catch(e) {
                this.alert("导出数据格式不正确!")
                return false
            }
        },
        // 本地批量导出文件
        exportCommonFiles(datas, fileNames, suffix, type) {
            try {
                window.$common.fullLoading()
                for (let i in datas) {
                    ((i) => {
                        setTimeout(() => {
                            this.exportCommonFile(datas[i], fileNames[i], suffix, type)
                            if (i >= datas.length - 1) {
                                window.$common.closeFullLoading()
                            }
                        }, (i + 1) * 50);
                    })(i)
                }
            } catch(e) {
                this.alert("导出数据格式不正确!")
                return false
            }
        },
        // 上下移动交换顺序
        swiperSort(arr, index1, index2) {
            arr[index1] = arr.splice(index2, 1, arr[index1])[0];
            return arr
        },
        // 操作枚举对象
        getEnumConfigs(key) {
            // 根据key获取当前需要的对象数组
            let temp = {
                arrs: [], // 数组
                objs: {}  // 键值对
            }
            temp.arrs = this.$store.getters.enumConfigs[key]
            for (let i in temp.arrs) {
                let item = temp.arrs[i]
                temp.objs[item.name] = {}
                for (let k in item) {
                    if (k != "name") {
                        temp.objs[item.name][k] = item[k]
                    }
                }
            }
            return temp
        },
        getEnumConfigsItem(items, name, key) {
            let val = items && items[name] && items[name][key]
            if (typeof val === "string") {
                val = val || ""
            } else {
                val = !val && val !== 0 ? null : val
            }
            return val
        },
        // 时间范围的快捷筛选，设置时间
        setPickerTime(picker, spaceTime, dateStr, isStartOf) {
            let timeArr = []
            let startTime = null
            let endTime = moment().format("YYYY-MM-DD 23:59:59")
            if (isStartOf) {
                startTime = moment().startOf(dateStr).format("YYYY-MM-DD 00:00:00")
            } else {
                startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
                // 因为取值是从0点开始的，所以会多出一天，开始时间需要往后延一天
                startTime = moment(startTime).add(1, "days").format("YYYY-MM-DD 00:00:00")
            }
            timeArr = [startTime, endTime]
            if (picker) {
                picker.$emit('pick', timeArr);
            }
            return timeArr
        },
        setBarcodeImage(code, name, options) {
            // 创建一个临时的canvas来绘制条形码
            const canvas = document.createElement("canvas")
            const ctx = canvas.getContext("2d")
            const barcode = JsBarcode(canvas, code, {
                format: options && options.format || "CODE128",
                width: options && options.width || 2,
                height: options && options.height || 40,
                displayValue: options && options.displayValue || false,
                fontSize: options && options.fontSize || 13,
                fontOptions: options && options.fontOptions || "",
                font: options && options.font || "monospace",
                textAlign: options && options.textAlign || "center",
                textPosition: options && options.textPosition || "bottom",
                textMargin: options && options.textMargin || 2,
                background: options && options.background || "#ffffff",
                lineColor: options && options.lineColor || "#000000",
                margin: options && options.margin || 10,
                marginTop: options && options.marginTop,
                marginBottom: options && options.marginBottom,
                marginLeft: options && options.marginLeft,
                marginRight: options && options.marginRight
            })
            // 将canvas转换为图片
            const image = new Image()
            image.src = canvas.toDataURL("image/png")
            return {
                data: image.src,
                name: name || "BarcodeToImage",
                extension: "png"
            }
        },
        setQRCodeImage(code, name, options) {
            // 使用qrcodejs2插件生成二维码
            const div = document.createElement("div")
            new QRCode(div, {
                text: code,
                width: options && options.width || 160,
                height: options && options.height || 160,
                colorDark: options && options.colorDark || "#000",
                colorLight: options && options.colorLight || "#ffffff",
                correctLevel: options && options.correctLevel || QRCode.CorrectLevel.L
            })
            let qrCanvas = div.getElementsByTagName("canvas")[0]
            let image = new Image()
            image.src = qrCanvas.toDataURL("image/png")
            return {
                data: image.src,
                name: name || "QRCodeToImage",
                extension: "png"
            }
        },
        // 判断当前激活的页面和路由页面是否相同
        mxIsSameActiveTabWithRoute(callback, isOnly) {
            let back = false
            if (isOnly) {
                if (this.$route.name === this.$options.name) {
                    back = true
                    callback && callback()
                }
            } else {
                back = true
                callback && callback()
            }
            return back
        },
        // 转换分页模式
        mxPageToSkipCount(item) {
            let r = JSON.parse(JSON.stringify(item))
            if (r.page) {
                r.skipCount = (r.page - 1) * r.size
                delete r.page
            }
            if (r.size) {
                r.maxResultCount = r.size
                delete r.size
            }
            return r
        },
        // 转换资产名称数据
        mxTranceAssetData(asset) {
            let back = ""
            try {
                if (asset.category && asset.barCode) {
                    back = `【${asset.category.name}-${asset.barCode}】`
                } else if (asset.category) {
                    back = `【${asset.category.name}】`
                } else if (asset.barCode) {
                    back = `【${asset.barCode}】`
                }
            } catch(ex) {
                console.log("资产名称转换异常!")
            }
            return back
        },
        // 节流  为了防止用户多次点击
        mxThrottle(fn, gapTime) {
			if (gapTime == null || gapTime == undefined) {
				gapTime = 1500
			}
			let _lastTime = null
			// 返回新的函数
			return function() {
				let _nowTime = +new Date()
				if (_nowTime - _lastTime > gapTime || !_lastTime) {
					// 将this和参数传给原函数
					fn.apply(this, arguments)
					_lastTime = _nowTime
				}
			}
		}
    }
}


